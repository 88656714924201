/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiVideoCourseChangeIsFreePut } from '../fn/video-course/api-video-course-change-is-free-put';
import { ApiVideoCourseChangeIsFreePut$Params } from '../fn/video-course/api-video-course-change-is-free-put';
import { apiVideoCourseChangeStatusPut } from '../fn/video-course/api-video-course-change-status-put';
import { ApiVideoCourseChangeStatusPut$Params } from '../fn/video-course/api-video-course-change-status-put';
import { apiVideoCourseChangeTeacherVideoStatusPut } from '../fn/video-course/api-video-course-change-teacher-video-status-put';
import { ApiVideoCourseChangeTeacherVideoStatusPut$Params } from '../fn/video-course/api-video-course-change-teacher-video-status-put';
import { apiVideoCourseGet } from '../fn/video-course/api-video-course-get';
import { ApiVideoCourseGet$Params } from '../fn/video-course/api-video-course-get';
import { apiVideoCourseGet$Plain } from '../fn/video-course/api-video-course-get-plain';
import { ApiVideoCourseGet$Plain$Params } from '../fn/video-course/api-video-course-get-plain';
import { apiVideoCourseGetStudentVideosByChapterGet } from '../fn/video-course/api-video-course-get-student-videos-by-chapter-get';
import { ApiVideoCourseGetStudentVideosByChapterGet$Params } from '../fn/video-course/api-video-course-get-student-videos-by-chapter-get';
import { apiVideoCourseGetStudentVideosByChapterGet$Plain } from '../fn/video-course/api-video-course-get-student-videos-by-chapter-get-plain';
import { ApiVideoCourseGetStudentVideosByChapterGet$Plain$Params } from '../fn/video-course/api-video-course-get-student-videos-by-chapter-get-plain';
import { apiVideoCourseGetStudentViewsGet } from '../fn/video-course/api-video-course-get-student-views-get';
import { ApiVideoCourseGetStudentViewsGet$Params } from '../fn/video-course/api-video-course-get-student-views-get';
import { apiVideoCourseGetStudentViewsGet$Plain } from '../fn/video-course/api-video-course-get-student-views-get-plain';
import { ApiVideoCourseGetStudentViewsGet$Plain$Params } from '../fn/video-course/api-video-course-get-student-views-get-plain';
import { apiVideoCourseGetVideoPendingTeacherVideosGet } from '../fn/video-course/api-video-course-get-video-pending-teacher-videos-get';
import { ApiVideoCourseGetVideoPendingTeacherVideosGet$Params } from '../fn/video-course/api-video-course-get-video-pending-teacher-videos-get';
import { apiVideoCourseGetVideoPendingTeacherVideosGet$Plain } from '../fn/video-course/api-video-course-get-video-pending-teacher-videos-get-plain';
import { ApiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Params } from '../fn/video-course/api-video-course-get-video-pending-teacher-videos-get-plain';
import { apiVideoCourseGetVideosCourseIdGet } from '../fn/video-course/api-video-course-get-videos-course-id-get';
import { ApiVideoCourseGetVideosCourseIdGet$Params } from '../fn/video-course/api-video-course-get-videos-course-id-get';
import { apiVideoCourseGetVideosCourseIdGet$Plain } from '../fn/video-course/api-video-course-get-videos-course-id-get-plain';
import { ApiVideoCourseGetVideosCourseIdGet$Plain$Params } from '../fn/video-course/api-video-course-get-videos-course-id-get-plain';
import { apiVideoCourseIdDelete } from '../fn/video-course/api-video-course-id-delete';
import { ApiVideoCourseIdDelete$Params } from '../fn/video-course/api-video-course-id-delete';
import { apiVideoCourseIdGet } from '../fn/video-course/api-video-course-id-get';
import { ApiVideoCourseIdGet$Params } from '../fn/video-course/api-video-course-id-get';
import { apiVideoCourseIdGet$Plain } from '../fn/video-course/api-video-course-id-get-plain';
import { ApiVideoCourseIdGet$Plain$Params } from '../fn/video-course/api-video-course-id-get-plain';
import { apiVideoCourseIdPut } from '../fn/video-course/api-video-course-id-put';
import { ApiVideoCourseIdPut$Params } from '../fn/video-course/api-video-course-id-put';
import { apiVideoCoursePost } from '../fn/video-course/api-video-course-post';
import { ApiVideoCoursePost$Params } from '../fn/video-course/api-video-course-post';
import { StudentWathedVideoDtoIEnumerablePagination } from '../models/student-wathed-video-dto-i-enumerable-pagination';
import { ViewVideoCourseDto } from '../models/view-video-course-dto';
import { ViewVideoCourseDtoIEnumerablePagination } from '../models/view-video-course-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class VideoCourseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVideoCourseGet()` */
  static readonly ApiVideoCourseGetPath = '/api/VideoCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGet$Plain$Response(params?: ApiVideoCourseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGet$Plain(params?: ApiVideoCourseGet$Plain$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGet$Response(params?: ApiVideoCourseGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGet(params?: ApiVideoCourseGet$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiVideoCoursePost()` */
  static readonly ApiVideoCoursePostPath = '/api/VideoCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCoursePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVideoCoursePost$Response(params?: ApiVideoCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCoursePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVideoCoursePost(params?: ApiVideoCoursePost$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseGetStudentViewsGet()` */
  static readonly ApiVideoCourseGetStudentViewsGetPath = '/api/VideoCourse/GetStudentViews';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetStudentViewsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentViewsGet$Plain$Response(params?: ApiVideoCourseGetStudentViewsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentWathedVideoDtoIEnumerablePagination>> {
    return apiVideoCourseGetStudentViewsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetStudentViewsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentViewsGet$Plain(params?: ApiVideoCourseGetStudentViewsGet$Plain$Params, context?: HttpContext): Observable<StudentWathedVideoDtoIEnumerablePagination> {
    return this.apiVideoCourseGetStudentViewsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentWathedVideoDtoIEnumerablePagination>): StudentWathedVideoDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetStudentViewsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentViewsGet$Response(params?: ApiVideoCourseGetStudentViewsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentWathedVideoDtoIEnumerablePagination>> {
    return apiVideoCourseGetStudentViewsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetStudentViewsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentViewsGet(params?: ApiVideoCourseGetStudentViewsGet$Params, context?: HttpContext): Observable<StudentWathedVideoDtoIEnumerablePagination> {
    return this.apiVideoCourseGetStudentViewsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentWathedVideoDtoIEnumerablePagination>): StudentWathedVideoDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseIdGet()` */
  static readonly ApiVideoCourseIdGetPath = '/api/VideoCourse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdGet$Plain$Response(params: ApiVideoCourseIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDto>> {
    return apiVideoCourseIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdGet$Plain(params: ApiVideoCourseIdGet$Plain$Params, context?: HttpContext): Observable<ViewVideoCourseDto> {
    return this.apiVideoCourseIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDto>): ViewVideoCourseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdGet$Response(params: ApiVideoCourseIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDto>> {
    return apiVideoCourseIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdGet(params: ApiVideoCourseIdGet$Params, context?: HttpContext): Observable<ViewVideoCourseDto> {
    return this.apiVideoCourseIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDto>): ViewVideoCourseDto => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseIdPut()` */
  static readonly ApiVideoCourseIdPutPath = '/api/VideoCourse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVideoCourseIdPut$Response(params: ApiVideoCourseIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCourseIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVideoCourseIdPut(params: ApiVideoCourseIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCourseIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseIdDelete()` */
  static readonly ApiVideoCourseIdDeletePath = '/api/VideoCourse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdDelete$Response(params: ApiVideoCourseIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCourseIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseIdDelete(params: ApiVideoCourseIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCourseIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseGetStudentVideosByChapterGet()` */
  static readonly ApiVideoCourseGetStudentVideosByChapterGetPath = '/api/VideoCourse/GetStudentVideosByChapter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetStudentVideosByChapterGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentVideosByChapterGet$Plain$Response(params?: ApiVideoCourseGetStudentVideosByChapterGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGetStudentVideosByChapterGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetStudentVideosByChapterGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentVideosByChapterGet$Plain(params?: ApiVideoCourseGetStudentVideosByChapterGet$Plain$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGetStudentVideosByChapterGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetStudentVideosByChapterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentVideosByChapterGet$Response(params?: ApiVideoCourseGetStudentVideosByChapterGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGetStudentVideosByChapterGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetStudentVideosByChapterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetStudentVideosByChapterGet(params?: ApiVideoCourseGetStudentVideosByChapterGet$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGetStudentVideosByChapterGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseGetVideosCourseIdGet()` */
  static readonly ApiVideoCourseGetVideosCourseIdGetPath = '/api/VideoCourse/GetVideosCourseId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetVideosCourseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideosCourseIdGet$Plain$Response(params?: ApiVideoCourseGetVideosCourseIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGetVideosCourseIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetVideosCourseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideosCourseIdGet$Plain(params?: ApiVideoCourseGetVideosCourseIdGet$Plain$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGetVideosCourseIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetVideosCourseIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideosCourseIdGet$Response(params?: ApiVideoCourseGetVideosCourseIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
    return apiVideoCourseGetVideosCourseIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetVideosCourseIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideosCourseIdGet(params?: ApiVideoCourseGetVideosCourseIdGet$Params, context?: HttpContext): Observable<ViewVideoCourseDtoIEnumerablePagination> {
    return this.apiVideoCourseGetVideosCourseIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>): ViewVideoCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseGetVideoPendingTeacherVideosGet()` */
  static readonly ApiVideoCourseGetVideoPendingTeacherVideosGetPath = '/api/VideoCourse/GetVideoPendingTeacherVideos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetVideoPendingTeacherVideosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Response(params?: ApiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewVideoCourseDto>>> {
    return apiVideoCourseGetVideoPendingTeacherVideosGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideoPendingTeacherVideosGet$Plain(params?: ApiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Params, context?: HttpContext): Observable<Array<ViewVideoCourseDto>> {
    return this.apiVideoCourseGetVideoPendingTeacherVideosGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewVideoCourseDto>>): Array<ViewVideoCourseDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseGetVideoPendingTeacherVideosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideoPendingTeacherVideosGet$Response(params?: ApiVideoCourseGetVideoPendingTeacherVideosGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewVideoCourseDto>>> {
    return apiVideoCourseGetVideoPendingTeacherVideosGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseGetVideoPendingTeacherVideosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseGetVideoPendingTeacherVideosGet(params?: ApiVideoCourseGetVideoPendingTeacherVideosGet$Params, context?: HttpContext): Observable<Array<ViewVideoCourseDto>> {
    return this.apiVideoCourseGetVideoPendingTeacherVideosGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewVideoCourseDto>>): Array<ViewVideoCourseDto> => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseChangeStatusPut()` */
  static readonly ApiVideoCourseChangeStatusPutPath = '/api/VideoCourse/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeStatusPut$Response(params?: ApiVideoCourseChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCourseChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeStatusPut(params?: ApiVideoCourseChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCourseChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseChangeIsFreePut()` */
  static readonly ApiVideoCourseChangeIsFreePutPath = '/api/VideoCourse/ChangeIsFree';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseChangeIsFreePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeIsFreePut$Response(params?: ApiVideoCourseChangeIsFreePut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCourseChangeIsFreePut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseChangeIsFreePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeIsFreePut(params?: ApiVideoCourseChangeIsFreePut$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCourseChangeIsFreePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVideoCourseChangeTeacherVideoStatusPut()` */
  static readonly ApiVideoCourseChangeTeacherVideoStatusPutPath = '/api/VideoCourse/ChangeTeacherVideoStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVideoCourseChangeTeacherVideoStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeTeacherVideoStatusPut$Response(params?: ApiVideoCourseChangeTeacherVideoStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVideoCourseChangeTeacherVideoStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVideoCourseChangeTeacherVideoStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVideoCourseChangeTeacherVideoStatusPut(params?: ApiVideoCourseChangeTeacherVideoStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiVideoCourseChangeTeacherVideoStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
