/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AdminDashboardDataDto } from '../models/admin-dashboard-data-dto';
import { apiStatsGetAdminDashboardDataGet } from '../fn/stats/api-stats-get-admin-dashboard-data-get';
import { ApiStatsGetAdminDashboardDataGet$Params } from '../fn/stats/api-stats-get-admin-dashboard-data-get';
import { apiStatsGetAdminDashboardDataGet$Plain } from '../fn/stats/api-stats-get-admin-dashboard-data-get-plain';
import { ApiStatsGetAdminDashboardDataGet$Plain$Params } from '../fn/stats/api-stats-get-admin-dashboard-data-get-plain';
import { apiStatsGetStudentDashboardDataGet } from '../fn/stats/api-stats-get-student-dashboard-data-get';
import { ApiStatsGetStudentDashboardDataGet$Params } from '../fn/stats/api-stats-get-student-dashboard-data-get';
import { apiStatsGetStudentDashboardDataGet$Plain } from '../fn/stats/api-stats-get-student-dashboard-data-get-plain';
import { ApiStatsGetStudentDashboardDataGet$Plain$Params } from '../fn/stats/api-stats-get-student-dashboard-data-get-plain';
import { apiStatsGetTeacherDashboardDataGet } from '../fn/stats/api-stats-get-teacher-dashboard-data-get';
import { ApiStatsGetTeacherDashboardDataGet$Params } from '../fn/stats/api-stats-get-teacher-dashboard-data-get';
import { apiStatsGetTeacherDashboardDataGet$Plain } from '../fn/stats/api-stats-get-teacher-dashboard-data-get-plain';
import { ApiStatsGetTeacherDashboardDataGet$Plain$Params } from '../fn/stats/api-stats-get-teacher-dashboard-data-get-plain';
import { StudentDashboardDataDto } from '../models/student-dashboard-data-dto';
import { TeacherDashboardDto } from '../models/teacher-dashboard-dto';

@Injectable({ providedIn: 'root' })
export class StatsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStatsGetAdminDashboardDataGet()` */
  static readonly ApiStatsGetAdminDashboardDataGetPath = '/api/Stats/GetAdminDashboardData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetAdminDashboardDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetAdminDashboardDataGet$Plain$Response(params?: ApiStatsGetAdminDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDashboardDataDto>> {
    return apiStatsGetAdminDashboardDataGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetAdminDashboardDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetAdminDashboardDataGet$Plain(params?: ApiStatsGetAdminDashboardDataGet$Plain$Params, context?: HttpContext): Observable<AdminDashboardDataDto> {
    return this.apiStatsGetAdminDashboardDataGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDashboardDataDto>): AdminDashboardDataDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetAdminDashboardDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetAdminDashboardDataGet$Response(params?: ApiStatsGetAdminDashboardDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDashboardDataDto>> {
    return apiStatsGetAdminDashboardDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetAdminDashboardDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetAdminDashboardDataGet(params?: ApiStatsGetAdminDashboardDataGet$Params, context?: HttpContext): Observable<AdminDashboardDataDto> {
    return this.apiStatsGetAdminDashboardDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDashboardDataDto>): AdminDashboardDataDto => r.body)
    );
  }

  /** Path part for operation `apiStatsGetStudentDashboardDataGet()` */
  static readonly ApiStatsGetStudentDashboardDataGetPath = '/api/Stats/GetStudentDashboardData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetStudentDashboardDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetStudentDashboardDataGet$Plain$Response(params?: ApiStatsGetStudentDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentDashboardDataDto>> {
    return apiStatsGetStudentDashboardDataGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetStudentDashboardDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetStudentDashboardDataGet$Plain(params?: ApiStatsGetStudentDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StudentDashboardDataDto> {
    return this.apiStatsGetStudentDashboardDataGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentDashboardDataDto>): StudentDashboardDataDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetStudentDashboardDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetStudentDashboardDataGet$Response(params?: ApiStatsGetStudentDashboardDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentDashboardDataDto>> {
    return apiStatsGetStudentDashboardDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetStudentDashboardDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetStudentDashboardDataGet(params?: ApiStatsGetStudentDashboardDataGet$Params, context?: HttpContext): Observable<StudentDashboardDataDto> {
    return this.apiStatsGetStudentDashboardDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentDashboardDataDto>): StudentDashboardDataDto => r.body)
    );
  }

  /** Path part for operation `apiStatsGetTeacherDashboardDataGet()` */
  static readonly ApiStatsGetTeacherDashboardDataGetPath = '/api/Stats/GetTeacherDashboardData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetTeacherDashboardDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetTeacherDashboardDataGet$Plain$Response(params?: ApiStatsGetTeacherDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherDashboardDto>> {
    return apiStatsGetTeacherDashboardDataGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetTeacherDashboardDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetTeacherDashboardDataGet$Plain(params?: ApiStatsGetTeacherDashboardDataGet$Plain$Params, context?: HttpContext): Observable<TeacherDashboardDto> {
    return this.apiStatsGetTeacherDashboardDataGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherDashboardDto>): TeacherDashboardDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatsGetTeacherDashboardDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetTeacherDashboardDataGet$Response(params?: ApiStatsGetTeacherDashboardDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherDashboardDto>> {
    return apiStatsGetTeacherDashboardDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatsGetTeacherDashboardDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStatsGetTeacherDashboardDataGet(params?: ApiStatsGetTeacherDashboardDataGet$Params, context?: HttpContext): Observable<TeacherDashboardDto> {
    return this.apiStatsGetTeacherDashboardDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherDashboardDto>): TeacherDashboardDto => r.body)
    );
  }

}
